import Circle from "@mui/icons-material/Circle";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { createUseGridApiEventHandler, DataGrid, frFR } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../services/API";
import Input from "../../components/Input"
import { UserContext } from "../../services/UserContext"
import { Delete } from "@mui/icons-material";

export default function Recherche({}){
    const [tickets, setTickets] = useState([])
    const [recherche, setRecherche] = useState("")
    const [resultats, setResultats] = useState([])
    let navigate = useNavigate();
    const [userContext, setUserContext] = useContext(UserContext)

    const colorsCircle ={"Brouillon":"red",
        "En attente":"#7030a0",
        "En attente de pièces":"#ffd966",
        "Planifié":"#00b0f0",
        "En cours d'intervention":"#8faadc",
        "Annulé":"#c00000",
        "Intervention terminée":"#a9d18e"}

    const columns = [
        {field: 'actions', headerName: '', sortable: false, 
            renderCell: (params) => {
              const onClickDelete = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer ce ticket ? Si vous souhaitez conserver une trace de ce ticket, il est préférable de l'annuler plutôt que de le supprimer.")
                if(conf){
                    handleDelete(params.row)
                }
              };
        
              return(
                    <IconButton color="primary" component="span" onClick={onClickDelete}><Delete/></IconButton>
              )
            },
        },
        { field: 'identifiant', headerName: 'Numéro', flex:1},
        { field: 'Statut modifié', headerName: 'Statut', flex:1,
            renderCell: (params) => {
                return <><Circle sx={{ color: colorsCircle[params.value], width:"12px",mr:0.5}}/>{params.value}</>
            }
        },
        { field: 'client', headerName: 'Client', flex:1},
        { field: 'dateCreation', headerName: 'Date', flex:1,
            valueGetter:(params)=>{
                return new Date(params.row.dateCreation.replace(/-/g, "/")).toLocaleDateString()
            }    
        },
    ];

    useEffect(()=>{
        API("GET","utilisateur")
        .then(res=>{
            let techniciens = res.json
            API("GET","ticket")
            .then(res=>{
                res.json.map(r=>{
                    r.fullname = r.prenom.toLowerCase() + " " + r.nom.toLowerCase()
                    let tech = techniciens.find(t=>t.id==r['Technicien attribué'])
                    r.technicien = tech?tech.prenom.toLowerCase() + " " + tech.nom.toLowerCase():""
                })
                setTickets(res.json)
                setResultats(res.json)
            })
        })
    },[])

    const onSubmit=(e)=>{
        e.preventDefault()
        let tmp = []
        tmp = tmp.concat(tickets.filter(t=>t.identifiant?.toLowerCase().includes(recherche.toLowerCase())))
        tmp = tmp.concat(tickets.filter(t=>t.fullname.includes(recherche.toLowerCase())))
        tmp = tmp.concat(tickets.filter(t=>t.technicien.includes(recherche.toLowerCase())))
        tmp = tmp.concat(tickets.filter(t=>t.dateCreation.includes(recherche)))
        tmp = tmp.filter((el,pos)=>tmp.indexOf(tmp.find(t=>t.id==el.id))==pos)
        console.log("tmp",tmp)
        setResultats(tmp)
    }

    const handleDelete=(row)=>{
        API("DELETE","ticket",row)
        .then(res=>{
            if(res.ok){
                setTickets(tickets.filter(t=>t.id!=row.id))
                setResultats(currVal=>currVal.filter(t=>t.id!=row.id))
            }else{
                alert("Une erreur s'est produite lors de la suppression du ticket. Veuillez contacter l'administrateur")
                console.warn("Erreur suppression ticket",res)
            }
        })
    }

    return(
        <>
            <Typography variant="h4" sx={{padding:"30px"}}>Recherche des tickets</Typography>

            <Box sx={{display:"inline"}}>
                <form onSubmit={onSubmit}>
                    <Input name="Numéro, Client, Attribué à ou Date" onChange={setRecherche} style={{width:350, marginRight:30}} InputProps={{sx:{/*py:"7px",*/ bgcolor:"white"}}} otherProps={{size:"small"}} />
                    <Button type="submit" variant="contained">Rechecher</Button>
                </form>
            </Box>

            <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                rows={resultats}
                rowHeight={40}
                autoHeight
                disableSelectionOnClick 
                onRowClick={(params, event) => {
                    navigate("../ticket/"+params.id)
                }}
                sx={{bgcolor:"white", mt:"20px", border:"1px solid",borderColor:"gris.contour", borderRadius:0,
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid gray`,
                    },
                    '& .MuiDataGrid-columnHeaders':{
                        fontSize:"1.1rem",
                        fontWeight:600
                    },
                }}
            />
        </>
    )
}
