import React, {useEffect, useState} from 'react';
import { MenuItem, TextField, Autocomplete} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';



export default function Dropdown_Statut({name, defaultValue, reloadDefVal, required, options, helperText, onChange, disabled=false, style, inputProps}){
    const [selected, select] = useState(defaultValue)
    const [error, setError] = useState(false)
    const [ht, setHt] = useState("")

    useEffect(()=>{
        select(defaultValue)
    },[defaultValue, reloadDefVal])

    useEffect(()=>{
        if(!helperText) return
        setHt(helperText.text)
        setError(helperText.type==="error")
    },[helperText])
    
    /*if(required){
        name=name.concat(" *")
    }*/

    const handleChange= (ev)=>{ 
        let v=ev.target.value
        select(v)
        if(required && v ===""){
            setError(true)
            onChange(undefined)
        }else{
            setError(false)
            onChange(v)
        }
    }
    
    return(
        <TextField select label={name} value={selected} onChange={handleChange} disabled={disabled} helperText={ht} error={error} 
            style={{width:"100%",...style}} 
            sx={{"& > .MuiSelect-select":{display: "inlineFlex", alignItems:"center"}}} 
            InputProps={{...inputProps, sx:{"& .MuiSelect-select":{p:0,display: "inline-flex", alignItems:"center"}}}}
        > 
            {options.length && options.map((o)=>(
                <MenuItem key={o.id} value={o.id} disabled={o.disabled}><CircleIcon sx={{ color: o.color}}/>{o.name}</MenuItem>
            ))}
        </TextField>
    )

    /*return(
        <TextField select label={name} value={selected} onChange={handleChange} helperText={ht} error={error} style={style} > 
            <MenuItem key={0} value="" disabled></MenuItem>
            {options.length && options.map((o)=>(
                <MenuItem key={o.id} value={o.id} disabled={o.disabled}>{o.name}</MenuItem>
            ))};
        </TextField>
    )*/
}