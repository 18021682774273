import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import Input from "../../components/Input";
import API from "../../services/API";
import { Object_isEqual, Object_whatIsDiff } from "../../services/ObjectHelper";
import { UserContext } from "../../services/UserContext"




export default function Utilisateur({}){
    let { id } = useParams()
    const [isCreation, setIsCreation] = useState(true)
    const [itsMe, setItsMe] = useState(false)
    const [userContext, setUserContext] = useContext(UserContext)
    const [oldData, setOldData] = useState({})
    const [answers, setAnswers] = useState({nom:undefined,prenom:undefined,username:undefined, role:"technicien"})
    const [answersOk, setAnswersOk] = useState(false)
    const [newPassword, setNewPassword] = useState(["",""])
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [passwordReinit, setPasswordReinit] = useState(false)
    const [sending, setSending] = useState(false)
    let navigate = useNavigate();

    useEffect(()=>{
        if(id==="creation"){
            setAnswers(currVal=>({...currVal,password:Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 10)}))
        }else{
            setIsCreation(false)
            setItsMe(id==userContext.id)
            API("GET","utilisateur","",{id:id})
            .then(res=>{
                console.log(res.json[0])
                setOldData(res.json[0])
                setAnswers(res.json[0])
            })
        }
    },[id])

    const handleChange=(key, value)=>{
        setAnswers(currVal => ({...currVal, [key]:value}))
    }

    useEffect(()=>{
        let notUndefined =  Object.values(answers).filter(a=>a==undefined).length===0
        let modif = !isCreation && !Object_isEqual(answers, oldData)
        setAnswersOk(notUndefined && (isCreation || modif))
    },[answers])

    const handleSend = ()=>{
        setSending(true)
        API("POST","utilisateur/signup",{...answers, signin:false})
        .then(res=>{
            if(res.ok){
                navigate("/admin/utilisateurs")
            }else{
                setSending(false)
                console.error("Erreur lors de la création de l'utilisateur" , res)
            }
        })
    }

    const handleReinitPassword = ()=>{
        setAnswers(currVal=>({...currVal, password:Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 10)}))
        setPasswordReinit(true)
    }
    const handleNouveauMDP = (num, value)=>{
        let tmp = []
        setNewPassword(currVal=>{
            tmp = currVal
            tmp[num]=value
            return tmp
        })
        if(tmp[0]!==""){
            if(tmp[0]===tmp[1]){
                setNewPasswordError(false)
                setAnswers(currVal=>({...currVal, password:tmp[0]}))
            }else{
                setNewPasswordError(true)
            }
        }else{
            setNewPasswordError(false)
        }
    }
    const handleModify = ()=>{
        let toSave = Object_whatIsDiff(answers,oldData)
        toSave = Object.fromEntries(Object.entries(answers).filter(([k,v])=>toSave.includes(k)))
        setSending(true)
        API("PUT","utilisateur",{...toSave,id:oldData.id})
        .then(res=>{
            if(res.ok){
                navigate("/admin/utilisateurs")
            }else{
                setSending(false)
                console.error("Erreur lors de la création de l'utilisateur" , res)
            }
        })
    }

    return(
        <>
        {!sending &&
            <Box sx={{border:"1px solid gray", p:"20px",position:"relative",top:"100px"}}> 
                <Typography variant="h6" sx={{bgcolor:"background.main", fontWeight:"bolder", fontSize:"1rem", width: "fit-content", backgroundColor: "background.main", position: "relative", top: -39, left: 10, padding: "5px"}}>
                    {isCreation? "Création" : "Modification"} utilisateur
                </Typography>

                <Box  sx={{display:"grid", gridTemplateColumns:{md:"1fr 1fr",xs:"1fr"}, rowGap:1, columnGap: 4}}>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Nom *</Typography>
                        <Input required={true} defaultValue={answers.nom} onChange={(v)=>handleChange("nom",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Prénom *</Typography>
                        <Input required={true} defaultValue={answers.prenom}  onChange={(v)=>handleChange("prenom",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Username *</Typography>
                        <Input required={true} defaultValue={answers.username} disabled={!isCreation} onChange={(v)=>handleChange("username",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box>
                        <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                            <Typography>{itsMe?"Nouveau mot de passe":"Mot de passe *"}</Typography>
                            {(isCreation || passwordReinit) && <Input required={true} defaultValue={answers.password||""} disabled={true} onChange={(v)=>handleChange("password",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />}
                            {!isCreation && !passwordReinit && !itsMe && <Button onClick={handleReinitPassword} variant="contained" color="primary" sx={{fontSize:"12px", width:200}}>Réinitialiser</Button>}
                            {itsMe &&
                                <Box sx={{width: "min-content"}}>
                                    <Input type="password" onChange={(v)=>handleNouveauMDP(0,v)} otherProps={{type:"password",error:newPasswordError?true:false}} style={{width:200}} InputProps={{placeholder:"Mot de passe",sx:{py:0, bgcolor:"white"}}}/>
                                    <Input type="password" onChange={(v)=>handleNouveauMDP(1,v)} otherProps={{type:"password",error:newPasswordError?true:false}} style={{width:200}} InputProps={{placeholder:"Répétition",sx:{py:0, bgcolor:"white"}}}/>
                                </Box>
                            }
                        </Box>
                        {isCreation && <Typography sx={{fontSize:12, textAlign:"center"}}>Veuillez enregistrer ce mot de passe</Typography>}
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Rôle</Typography>
                        <Dropdown options={[{id:"technicien",name:"Technicien"},{id:"admin",name:"Admin"}]} defaultValue={answers.role} disabled={itsMe} onChange={(v)=>handleChange("role",v)} style={{width:200}} inputProps={{sx:{"& > .MuiSelect-select":{py:"0 !important", bgcolor:"white"}}}} />
                    </Box>
                </Box>
                <Button disabled={!answersOk} onClick={isCreation ? handleSend:handleModify} variant="contained" color="primary" style={{display: "block", marginLeft: "auto", marginRight: 0, marginTop: 15}}>{isCreation ? "Créer":"Modifier"}</Button>
            </Box>
        }
        {sending && 
            <CircularProgress sx={{display:"block", margin:"auto"}} />
        }
        </>
    )
}