import React, {useEffect, useState} from 'react';
import { TextField, CircularProgress, Box } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function SearchableDropdown({name, defaultValue, required, options, helperText, onChange, disabled, style, loading, inputProps}){
    const [localOptions, setLocalOptions] = useState([])
    const [selected, select] = useState()
    const [error, setError] = useState(false)
    const [ht, setHt] = useState("")
    const [newDefaultValue, setNewDefaultValue] = useState(false)

    useEffect(()=>{
        if(defaultValue===undefined) return
        let found = localOptions.find(o=>o.nom===defaultValue)
        if(found)
            select(found)
        else{
            select({nom:defaultValue})
            setNewDefaultValue(true)
        }
    },[defaultValue,localOptions])

    useEffect(()=>{
        if(options.length===0) return
        //console.log(options)
        let tmp = options
        //tmp.unshift({id:0,name:"",disabled:true})
        setLocalOptions(tmp)
    },[options])

    useEffect(()=>{
        if(!helperText) return
        setHt(helperText.text)
        setError(helperText.type==="error")
    },[helperText])
    

    const handleChange=(event,client)=>{
        if(client.id==undefined){   //Nouveau client
            client.nom = client.inputValue
        }
        select(client)
        onChange(client)
        /*if(newValue === null){
            if(required){
                setError(true)
                onChange(undefined)
            }
        }else{
            setError(false) 
            onChange(newValue.id)
        }*/
    }
    const handleInputChange=(event,input)=>{
        let client = {nom : input}
        select(client)
        onChange(client)
    }

    return(
        <Autocomplete
            freeSolo={true}
            style={style}
            loading={loading}
            loadingText="Chargement..."
            options={localOptions}
            disabled={disabled}
            getOptionLabel={(option) => option.nom||''}
            getOptionDisabled={(option) => option.disabled}
            isOptionEqualToValue={(option, value) => (option.id === value.id)/*||(value==='' && option.name==='')*/}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                    {!!option.identifiant && <div>[{option.identifiant}] {option.prenom} {option.nom}</div>}
                    {!option.identifiant && <div style={{backgroundColor:"#a9d18e"}}>{option.nom}</div>}
                </Box>
                
            )}
            filterOptions={(options, params) => {
                if(newDefaultValue && selected.id===undefined){
                    setNewDefaultValue(false)
                    params.inputValue = defaultValue
                }
                console.log("params", params)
                const filtered = filter(options, params);
                const { inputValue } = params;
                filtered.push({inputValue,nom: 'Nouveau '+inputValue,});
                return filtered;
            }}
            value={selected||''}
            onChange={handleChange}
            onInputChange={handleInputChange}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={name}  
                    variant="outlined" 
                    helperText={ht} 
                    error={error} 
                    InputProps={{
                        ...params.InputProps,
                        ...inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={10} /> : null}
                          </React.Fragment>
                        ),
                      }}
                />
            }
        />
    )
}

