import { Button, Typography, Box, Container } from "@mui/material"
import { useEffect, useState } from "react"
import FormulaireTicket from "../components/FormulaireTicket"
import Input from "../components/Input"
import Textarea from "../components/Textarea"
import API from "../services/API"
import { getSQLDatetimeNow } from "../services/DateHelper"

export default function Accueil({}){
    const [answers, setAnswers] = useState({nom:undefined,prenom:undefined,adresse:undefined,codePostal:undefined,ville:undefined,telephone:undefined,email:undefined,demande:undefined})
    const [answersOk, setAnswersOk] = useState(false)
    const [sent, setSent] = useState(false)

    const handleChange=(key, value)=>{
        setAnswers(currVal => ({...currVal, [key]:value}))
    }

    useEffect(()=>{
        setAnswersOk(Object.values(answers).filter(a=>a==undefined).length===0)
    },[answers])

    const handleSend=()=>{
        let date = getSQLDatetimeNow()
        answers["dateCreation"]= date
        API("POST","ticket",answers)
        .then(res=>{
            let ticketId = res.json.insertId
            API("POST","logticket",{date:date, Ticket_id:ticketId, quoi:"Ticket créé"})
            API("POST","logticket",{date:getSQLDatetimeNow(), Ticket_id:ticketId, quoi:"Statut modifié", donnee:"Brouillon"})

            setSent(true)
        })
    }

    return(
        <Container maxWidth="md">
            <Box >
                <img src="logo.png" style={{width:100, position:"absolute"}}/>
                <Typography variant="h4" component="h1" style={{textAlign:"center", height:100, lineHeight:"100px", marginBottom : 20}}>Outil de management des SAV</Typography>
            </Box>
            <FormulaireTicket admin={false}/>
        </Container>
    )
}