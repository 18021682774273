import Circle from "@mui/icons-material/Circle";
import { Typography, Box } from "@mui/material";
import { DataGrid, frFR } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../services/API";

export default function Rapport({}){
    const [tickets, setTickets] = useState([])
    let navigate = useNavigate();

    const colorsCircle ={"Brouillon":"red",
        "En attente":"#7030a0",
        "En attente de pièces":"#ffd966",
        "Planifié":"#00b0f0",
        "En cours d'intervention":"#8faadc",
        "Annulé":"#c00000",
        "Intervention terminée":"#a9d18e"}

    const columns = [
        { field: 'identifiant', headerName: 'Numéro', flex:1},
        { field: 'Statut modifié', headerName: 'Statut', flex:1,
            renderCell: (params) => {
                return <><Circle sx={{ color: colorsCircle[params.value], width:"12px",mr:0.5}}/>{params.value}</>
            }
        },
        { field: 'client', headerName: 'Client', flex:1},
        { field: 'dateCreation', headerName: 'Date', flex:1,
            valueGetter:(params)=>{
                return new Date(params.row.dateCreation.replace(/-/g, "/")).toLocaleDateString()
            }    
        },
    ];

    useEffect(()=>{
        API("GET","ticket")
        .then(res=>{
            setTickets(res.json.filter(r=>r["Statut modifié"]=="Intervention terminée" || r["Statut modifié"]=="Annulé"))
        })
    },[])

    return(
        <>
            <Typography variant="h4" sx={{padding:"30px"}}>Rapports</Typography>

            <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                rows={tickets}
                rowHeight={40}
                autoHeight
                disableSelectionOnClick 
                onRowClick={(params, event) => {
                    navigate("../ticket/"+params.id)
                }}
                sx={{bgcolor:"white", mt:"20px", border:"1px solid",borderColor:"gris.contour", borderRadius:0,
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid gray`,
                    },
                    '& .MuiDataGrid-columnHeaders':{
                        fontSize:"1.1rem",
                        fontWeight:600
                    },
                }}
            />
        </>
    )
}
