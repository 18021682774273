import React, {useEffect, useState} from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';



export default function CheckboxItem({name, defaultValue, onChange}){
    const [state, setState] = useState(false)

    useEffect(()=>{
        setState(defaultValue)
        onChange(defaultValue)
    },[defaultValue])

    const handleChange=(event)=>{
        const s = event.target.checked
        setState(s)
        onChange(s)
    }
    return(
        <FormControlLabel
            control={
                <Checkbox
                    checked={state}
                    onChange={handleChange}
                    name={name}
                    color="primary"
                />
            }
            label={name}
        />
    )
}