import { Typography, Box, Button } from "@mui/material";
import { useEffect, useState } from "react"
import SearchableDropdown from "../../components/SearchableDropdown";
import Input from "../../components/Input"
import Textarea from "../../components/Textarea"
import API from "../../services/API"
import { getSQLDatetimeNow } from "../../services/DateHelper"
import FormulaireTicket from "../../components/FormulaireTicket";

export default function Creer({}){
    const [loadingClients, setLoadingClients] = useState(true)
    const [clients, setClients] = useState([])
    const [answers, setAnswers] = useState({nom:undefined,prenom:undefined,adresse:undefined,codePostal:undefined,ville:undefined,telephone:undefined,email:undefined,demande:undefined})
    const [answersClient, setAnswersClient] = useState({identifiant:undefined,nom:undefined,prenom:undefined})
    const [answersOk, setAnswersOk] = useState(false)
    const [sent, setSent] = useState(false)


    useEffect(()=>{
        API("GET","client")
        .then(res=>{
            //res.map(r=>r["name"]="["+r.identifiant+"] "+r.prenom+" "+r.nom)
            setClients(res.json)
            setLoadingClients(false)
        })
    },[])

    const onSelectClient=(client)=>{
        setAnswers(currVal => ({...currVal, Client_id:client.id}))
        setAnswersClient(currVal => ({...currVal, id:client.id, identifiant:client.identifiant, nom:client.nom, prenom:client.prenom}))
    }

    const handleChange=(key, value)=>{
        setAnswers(currVal => ({...currVal, [key]:value}))
    }
    const handleChangeClient=(key, value)=>{
        setAnswersClient(currVal => ({...currVal, [key]:value}))
    }

    useEffect(()=>{
        setAnswersOk(Object.values(answers).filter(a=>a==undefined).length===0)
    },[answers])

    useEffect(()=>{
        if(answersClient.id==undefined){
            let newIdentifiant = "41"+(answersClient.nom||"").substr(0, 3).toUpperCase()+(answersClient.prenom||"").substr(0, 2).toUpperCase()
            if(newIdentifiant == answersClient.identifiant) return
            setAnswersClient(currVal=>({...currVal, identifiant:newIdentifiant}))
        }
    },[answersClient])

    const handleSend=()=>{
        let date = getSQLDatetimeNow()
        answers["dateCreation"]= date
        answers["Client_id"]="0"
        API("POST","ticket",answers)
        .then(res=>{
            let ticketId = res.json.insertId
            API("POST","logticket",{date:date, Ticket_id:ticketId, quoi:"Ticket créé"})
            API("POST","logticket",{date:getSQLDatetimeNow(), Ticket_id:ticketId, quoi:"Statut modifié", donnee:"Brouillon"})

            setSent(true)
        })
    }
    return(
        <>
            <div style={{height:20}}></div>
            <FormulaireTicket admin={true} callback={()=>{}}/>
        </>
    )
}