import { Typography, Box, Button, Modal, CircularProgress, TextField } from "@mui/material";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import { useContext, useEffect, useState } from "react"
import SearchableDropdown from "./SearchableDropdown";
import Dropdown from "./Dropdown";
import Input from "./Input"
import Textarea from "./Textarea"
import API from "../services/API"
import { getSQLDatetimeNow } from "../services/DateHelper"
import { Object_isEqual, Object_whatIsDiff } from "../services/ObjectHelper";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../services/UserContext"
//import codesPostaux from "../services/codesPostaux"

//const codesPostaux = require('codes-postaux');
const filter = createFilterOptions();

export default function FormulaireTicket({admin, data, openPopup, callback, disabled=false}){
    const [userContext, setUserContext] = useContext(UserContext)
    const [loadingClients, setLoadingClients] = useState(true)
    const [clients, setClients] = useState([])
    const [cp, setCp] = useState([])
    const [inputVille, setInputVille] = useState("")
    const [answers, setAnswers] = useState({nom:undefined,prenom:undefined,demande:undefined})
    const [answersClient, setAnswersClient] = useState({identifiant:undefined,nom:undefined,prenom:undefined})
    const [answersOk, setAnswersOk] = useState(false)
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)
    const [openPopupLocal, setOpenPopupLocal] = useState(false)
    let navigate = useNavigate();
    

    useEffect(()=>{
        fetch('/codesPostaux2.json',{headers : { 'Content-Type': 'application/json','Accept': 'application/json'}})
        .then(res=> res.json())
        .then(json=> setCp(json))

        if(!admin) return
        //setAnswers(currVal=>({...currVal,Client_id:undefined}))
        API("GET","client")
        .then(res=>{
            setClients(res.json)
            setLoadingClients(false)
        })
    },[])

    useEffect(()=>{
        if(data===undefined) return
        if(!data.ticket || !Object.keys(data.ticket).length) return
        Object.entries(data.ticket).map(([key,val])=>{
            if(val==undefined){
                data.ticket[key]=""
            }
        })
        setAnswers(data.ticket)
        if(!data.client || !Object.keys(data.client).length){
            setAnswersClient({prenom:data.ticket.prenom, nom:data.ticket.nom})
        }else{
            setAnswersClient(data.client)
        }
    },[data])

    const onSelectClient=(client)=>{
        if(client.id){
            setAnswers(currVal => ({...currVal, Client_id:client.id, nom:client.nom, prenom:client.prenom}))
            setAnswersClient(currVal => ({...currVal, id:client.id, identifiant:client.identifiant, nom:client.nom, prenom:client.prenom}))
        }else{
            setAnswers(currVal => {
                delete currVal.Client_id
                return {...currVal, nom:client.nom}
            })
            setAnswersClient(currVal => ({...currVal, nom:client.nom}))
        }
    }
    const handleChange=(key, value)=>{
        setAnswers(currVal => ({...currVal, [key]:value}))

        /*if(key==="codePostal"){
            let tmp = codesPostaux.find(value)
            setVilles(tmp.length? tmp.map(t=>t.nomCommune,):[])
        }*/
    }
    const handleChangeClient=(key, value)=>{
        setAnswersClient(currVal => ({...currVal, [key]:value}))
        if(key==="nom"||key==="prenom"){
            setAnswers(currVal => {
                delete currVal.Client_id
                return {...currVal, [key]:value}
            })
        }
        /*if(onChangeClient!==undefined){
            onChangeClient(answersClient)
        }*/
    }
    useEffect(()=>{
        console.log("answers", answers)
        //console.log("data.ticket", data.ticket)
        let notUndefined =  Object.values(answers).filter(a=>a==undefined).length===0
        let modif = !!data && !!data.ticket && !Object_isEqual(answers, data.ticket)
        setAnswersOk(notUndefined && (!data || modif))
    },[answers])

    useEffect(()=>{
        if(admin && answersClient.id==undefined){
            let newIdentifiant = "41"+(answersClient.nom||"").sanitize(3) + (answersClient.prenom||"").sanitize(2)
            if(newIdentifiant == answersClient.identifiant) return
            setAnswersClient(currVal=>({...currVal, identifiant:newIdentifiant}))
        }
    },[answersClient])
    

    const handleSend=()=>{
        setSending(true)
        if(!admin){
            creationTicket()
            .then(ticketId=>{
                setSent(true)
                setSending(false)
            })
        }else{
            setOpenPopupLocal(true)
        }
    }

    const creationTicket=()=>{
        let date = getSQLDatetimeNow()
        answers["dateCreation"]= date
        return API("POST","ticket",answers)
        .then(res=>{
            let ticketId = res.json.insertId
            //setTicketIdCreated(res.json.insertId)
            console.log("res POST Ticket",res)
            API("POST","logticket",{date:date, Ticket_id:ticketId, Qui:admin?userContext.id:undefined, quoi:"Ticket créé"}).then(res=>console.log("res POST logTicket1",res))
            API("POST","logticket",{date:getSQLDatetimeNow(), Ticket_id:ticketId, Qui:admin?userContext.id:undefined, quoi:"Statut modifié", donnee:admin?"En attente":"Brouillon"}).then(res=>console.log("res POST logTicket2",res))
            return ticketId
        })
    }

    const handleModify=()=>{
        let toSave = Object_whatIsDiff(answers,data.ticket)
        toSave = Object.fromEntries(Object.entries(answers).filter(([k,v])=>toSave.includes(k)))
        API("PUT","ticket",{id:data.ticket.id,...toSave})
    }

    const callbackPopup = (val,nbSim=undefined)=>{
        let promise
        if(val===false){
            callback(val)
            setSending(false)
            setOpenPopupLocal(false)
            return 
        }

        if(openPopupLocal){ // si c'est une création dans l'admin)
            promise = creationTicket()
        }else{ //validation (=>en attente) d'un ticket brouillon
            promise = new Promise((resolve, reject) => { resolve(data.ticket.id)})
        }
        
        promise.then(ticketId=>{
            if(val===undefined){
                if(nbSim){
                    answersClient.identifiant = answersClient.identifiant+nbSim
                }
                return API("POST","client",answersClient)
                .then(res=>{
                    return API("PUT", "ticket",{id:ticketId, Client_id:res.json.insertId, Client_identifiant:answersClient.identifiant})
                    .then(res2=>{
                        return {id:ticketId, identifiant:res2.json.ticketIdentifiant}
                        /*if(!openPopupLocal){
                            callback(val, res2.json.ticketIdentifiant)
                        } */
                    })
                })
            }else if(val){
                let selectedClient = clients.find(c=>c.id==val)
                if(selectedClient.id != answersClient.id) setAnswersClient(selectedClient)
                if(selectedClient.id != data?.client.id){
                    return API("PUT", "ticket",{id:ticketId, Client_id:selectedClient.id, Client_identifiant:selectedClient.identifiant})
                    .then(res=>{
                        return {id:ticketId, identifiant:res.json.ticketIdentifiant}
                        /*if(!openPopupLocal) {
                            callback(val, res.json.ticketIdentifiant)
                        }*/
                    })
                }
            }
        }).then(ticket=>{
            if(openPopupLocal){ // si c'est une création dans l'admin
                setOpenPopupLocal(false)
                navigate("../ticket/"+ticket.id)
            }else{
                callback(val, ticket.identifiant)
            }
        })
        
        
    }

    return(
        <>
        {!sent && !sending &&
            <Box sx={{border:"1px solid gray", p:"20px",mt:"20px"}}> 
                <Typography variant="h6" sx={{bgcolor:"background.main", fontWeight:"bolder", fontSize:"1rem", width: "fit-content", backgroundColor: "background.main", position: "relative", top: -39, left: 10, padding: "5px"}}>
                    Création de ticket
                </Typography>

                <Box  sx={{display:"grid", gridTemplateColumns:{md:"1fr 1fr",xs:"1fr"}, rowGap:1, columnGap: 4}}>
                    {admin && 
                        <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                            <Typography>Identifiant client</Typography>
                            <Input required={true} defaultValue={answersClient.identifiant} disabled={true} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                        </Box>
                    }{admin &&   
                        <div></div>
                    }{admin && 
                        <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                            <Typography>Nom *</Typography>
                            <SearchableDropdown defaultValue={answersClient.nom} disabled={data && data.ticket["Statut modifié"]!="Brouillon"} inputProps={{sx:{py:"0 !important", "& input":{py:"0 !important"}}}} options={clients} onChange={onSelectClient} loading={loadingClients} style={{width:200, backgroundColor:"white"}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                        </Box>
                    }{!admin && 
                        <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                            <Typography>Nom *</Typography>
                            <Input required={true} onChange={(v)=>handleChangeClient("nom",v)} disabled={disabled} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                        </Box>
                    }
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Prénom *</Typography>
                        <Input required={true} defaultValue={answersClient.prenom} disabled={(data && data.ticket["Statut modifié"]!="Brouillon")||disabled} onChange={(v)=>handleChangeClient("prenom",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box sx={{gridRow: 'span 2', display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Adresse postale{!admin && " *"}</Typography>
                        <Textarea required={!admin} defaultValue={answers.adresse} disabled={disabled} onChange={(v)=>handleChange("adresse",v)} style={{width:200, "&>div":{py:0, backgroundColor:"white"} }}  />
                    </Box>
                    {/*<Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Code postal</Typography>
                        <Input required={true} defaultValue={answers.codePostal} disabled={disabled} onChange={(v)=>handleChange("codePostal",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>*/}
                    <Box sx={{gridRow: 'span 2', display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Ville{!admin && " *"}</Typography>
                        <Autocomplete
                            inputValue={inputVille}
                            onInputChange={(ev, newInputValue) => setInputVille(newInputValue)}
                            open={inputVille?.length >= 3 && !answers.ville}
                            options={cp}
                            onChange={(ev,val)=>handleChange("ville",val ? val : undefined)}
                            renderInput={(params) => <TextField multiline={true} rows={2} {...params} sx={{width:200, bgcolor:"white", "& .MuiOutlinedInput-root":{ py:0,fontSize:11,} /*.MuiAutocomplete-input":{py:"4px"}*/}} label="" placeholder="Veuillez taper au moins 3 caractères" />}
                            filterOptions={(options, state)=>{
                                const filtered = filter(options, state);
                                return filtered
                            }}
                            //getOptionLabel={(option)=> option.codePostal + " - " + option.nomCommune}
                            //isOptionEqualToValue={(option,value)=> { /*console.log("option",option,"value",value);*/ return value === option.codePostal+" - "+option.nomCommune}}
                            value={answers.ville||null}
                        />
                        {/*<Input required={true} defaultValue={answers.ville} disabled={disabled} onChange={(v)=>handleChange("ville",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />*/}
                        {/*<Dropdown options={villes} />*/}
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Téléphone{!admin && " *"}</Typography>
                        <Input required={!admin} defaultValue={answers.telephone} disabled={disabled} onChange={(v)=>handleChange("telephone",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Email</Typography>
                        <Input required={false} defaultValue={answers.email} disabled={disabled} onChange={(v)=>handleChange("email",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Nom du locataire<br/>(optionnel)</Typography>
                        <Input required={false} defaultValue={answers.locataire} disabled={disabled} onChange={(v)=>handleChange("locataire",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                    <Box sx={{gridRow: 'span 2', display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Lieu d'intervention<br/>(si différent)</Typography>
                        <Textarea required={false} defaultValue={answers.lieuIntervention} disabled={disabled} onChange={(v)=>handleChange("lieuIntervention",v)} nbLines={3} style={{width:200, "&>div":{py:0, backgroundColor:"white"} }}  />
                    </Box>
                    <Box sx={{display:"flex", justifyContent: 'space-between'}}>
                        <Typography>Téléphone du locataire<br/>(optionnel)</Typography>
                        <Input required={false} defaultValue={answers.telephoneLocataire} disabled={disabled} onChange={(v)=>handleChange("telephoneLocataire",v)} style={{width:200}} InputProps={{sx:{py:0, bgcolor:"white"}}} />
                    </Box>
                </Box>

                <Typography sx={{mt:"20px"}}>Votre demande *</Typography>
                <Textarea required={true} defaultValue={answers.demande} disabled={disabled} onChange={(v)=>handleChange("demande",v)} nbLines={4} style={{width:"100%", "&>div":{py:0, backgroundColor:"white"} }}  />

                <Button disabled={!answersOk||disabled} onClick={data===undefined ? handleSend:handleModify} variant="contained" color="primary" style={{display: "block", marginLeft: "auto", marginRight: 0, marginTop: 15}}>{data===undefined ? "Envoyer":"Modifier"}</Button>
            </Box>
        }
        {sending && 
            <CircularProgress sx={{display:"block", margin:"auto"}} />
        }
        {sent && 
            <Typography sx={{textAlign:"center"}}>Votre demande a bien été enregistrée</Typography>
        }

            <PopupSelectionClient open={openPopup || openPopupLocal} callback={callbackPopup} client={answersClient}/>
        </>
    )
}


function PopupSelectionClient({open, callback, client}){
    const [message, setMessage] = useState()
    const [clientsPotentiels, setClientsPotentiels] = useState([])
    const [processing, setProcessing] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(()=>{
        console.log("coucou")
    },[open])

    useEffect(()=>{
        if(!client || !client.identifiant){
            setClientsPotentiels([])
            return
        }
        setProcessing(true)
        API("GET","client",{},{identifiant:client.identifiant})
        .then(res=>{
            setClientsPotentiels(res.json)
            setProcessing(false)
        })
    },[client])


    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <>
                <Box sx={style}>
                    {processing && 
                        <CircularProgress sx={{display:"block",m:"auto"}}/>
                    }
                    {!processing && !clientsPotentiels.length &&
                        <>
                            <Typography variant="h6" component="h2">
                                Etes-vous sûr ?
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                En modifiant le statut depuis "Brouillon", vous n'aurez plus la possibilité de modifier le client.
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                Est-ce bien :
                            </Typography>
                            <Typography sx={{ textAlign:"center" }}>
                                {client.prenom} {client.nom} <br/>
                                {client.id?"(Client existant)":"(Création)"} 
                            </Typography>
                            <Box display="flex" justifyContent="end">
                                <Button variant="contained" color="secondary" sx={{m:1}} onClick={()=>callback(false)}>Non</Button>
                                <Button variant="contained" sx={{m:1}} onClick={()=>callback(client.id)}>Oui</Button>
                            </Box>
                        </>
                    }
                    {!processing && !!clientsPotentiels.length &&
                        <>
                            <Typography variant="h6" component="h2">
                                Un ou plusieurs clients ont le même identifiant
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                En modifiant le statut depuis "Brouillon", vous n'aurez plus la possibilité de modifier le client.
                            </Typography>
                            {clientsPotentiels.map(c=>(
                                <Box key={c.id} display="flex" sx={{my:2}}>
                                    <Typography>Est-ce  </Typography>
                                    <Typography sx={{ml:1,fontWeight:600 }}> {c.prenom} {c.nom}</Typography>
                                    <Button variant="contained" color="secondary" sx={{ml:"auto"}} onClick={()=>callback(c.id)}>Oui</Button>
                                </Box>
                            ))}
                            <Box display="flex">
                                <Typography sx={{ mt: 2 }}>Aucun ?</Typography>
                                <Button variant="contained" sx={{m:1}} onClick={()=>callback(undefined,clientsPotentiels.length)}>Création client</Button>
                            </Box>
                        </>
                    }
                </Box>
            </>
      </Modal>
    )
}


String.prototype.sanitize = function(nbreCar) {
    return this.replace(/\s/g, '').replace(/'/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().slice(0,nbreCar)
}