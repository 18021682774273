import { Button, Typography } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../services/API";



export default function Utilisateurs({}){
    const [utilisateurs, setUtilisateurs] = useState([])
    let navigate = useNavigate();

    const columns = [
        { field: 'prenom', headerName: 'Prénom', flex:1},
        { field: 'nom', headerName: 'Nom', flex:1},
        { field: 'role', headerName: 'Rôle', flex:1}
    ];

    useEffect(()=>{
        API("GET","utilisateur")
        .then(res=>setUtilisateurs(res.json))
    },[])



    return(
        <>
            <Typography variant="h4" sx={{padding:"30px"}}>Utilisateurs</Typography>
            <Button variant="outlined" sx={{display:"block",ml:"auto"}} onClick={()=>navigate("/admin/utilisateur/creation")}>Ajouter</Button>
            <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                rows={utilisateurs}
                rowHeight={40}
                autoHeight
                disableSelectionOnClick 
                onRowClick={(params, event) => {
                    navigate("/admin/utilisateur/"+params.id)
                }}
                sx={{bgcolor:"white", mt:"20px", border:"1px solid",borderColor:"gris.contour", borderRadius:0,
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid gray`,
                    },
                    '& .MuiDataGrid-columnHeaders':{
                        fontSize:"1.1rem",
                        fontWeight:600
                    },
                }}
            />
        </>
    )
}