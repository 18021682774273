const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
  "Juillet", "Août", "Septembere", "Octobre", "Novembre", "Décembre"];
export function getMonthName(m){
    return monthNames[m]
}

export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo]
}

export function getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

export function getWeekDaysFromMonday(d){
    let toReturn = []
    for(let i=0;i<7;i++){
        //let j=new Date(d.getFullYear(), d.getMonth(), d.getDate()+i)
        //toReturn.push(j.getDate())
        toReturn.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()+i))
    }
    return toReturn
}


export function getSQLDatetimeNow(){
    let date = new Date();
    date = date.getFullYear() + '-' +
        ('00' + (date.getMonth()+1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) + ' ' + 
        ('00' + date.getHours()).slice(-2) + ':' + 
        ('00' + date.getMinutes()).slice(-2) + ':' + 
        ('00' + date.getSeconds()).slice(-2);
    return date
}

export function Date_isEqual(a,b){
    if(a.getFullYear()===b.getFullYear() && a.getMonth()===b.getMonth() && a.getDate()===b.getDate())
        return true
    else
        return false
}

export function Date_isInWeek(d,w){
    for(let x of w){
        if(Date_isEqual(x,d))
            return true
    }
    return false
}

export function Date_localeStringToDate(str){
    return new Date(str.replace(/([0-9]+\/)([0-9]+\/)(.*)/g,"$2$1$3"))
}


//export  {getMonthName, getWeekNumber, getDateOfISOWeek, getWeekDaysFromMonday}