import React, { useEffect, useState, useContext } from 'react';
import { Button, Container, Divider, Grid, Box, Typography, CircularProgress, IconButton, TextField, ClickAwayListener } from '@mui/material';
import {getMonthName, getWeekNumber, getDateOfISOWeek, getWeekDaysFromMonday, Date_isEqual, Date_isInWeek, getSQLDatetimeNow} from '../services/DateHelper'
import {ArrowLeft, ArrowRight, ArrowDropUp, ArrowDropDown, Circle, Room} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
//import TimePicker from '@mui/lab/TimePicker';
import TimePicker from './TimePicker';
import API from '../services/API';
import { UserContext } from "../services/UserContext"
import { set } from 'date-fns';

export default function DatePicker({technicien, disabled, ticket, onChange}){
    const [userContext, setUserContext] = useContext(UserContext)
    const theme = useTheme()
    const [open, setOpen] = React.useState(false);

    const [today, setToday] = useState(new Date())
    const [weekNum, setWeekNum] = useState(getWeekNumber(new Date())[1])
    const [year, setYear] = useState(new Date().getFullYear())
    const [month, setMonth] = useState([new Date().getMonth(), "init"])
    const [week, setWeek] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [filledDate, setFilledDate] = useState(undefined)

    const [calendars, setCalendars] = useState([])
    const [events, setEvents] = useState([]);
    const [calendarUser, setCalendarUser] = useState(undefined)

    const [inputValue, setInputValue] = useState("")
    const [heureDebut, setHeureDebut] = useState()
    const [heureFin, setHeureFin] = useState()

    const [messageErreur, setMessageErreur] = useState("")

    useEffect(()=>{
        let tmp = new Date()
        tmp.setHours(8)
        tmp.setMinutes(0)
        tmp.setSeconds(0)
        setHeureDebut(new Date(tmp.getTime()))
        tmp.setHours(9)
        setHeureFin(new Date(tmp.getTime()))
    },[])

    useEffect(()=>{
        //if(!technicien) return
        console.log("tech",technicien)
    },[technicien])    
    
    useEffect(()=>{
        if(ticket["Intervention planifiée"]==undefined) return 
        //debugger
        setInputValue(ticket["Intervention planifiée"])
        //if(ticket["Intervention planifiée"]==="") return 
        const regex = /(\d{2})(\/\d{2}\/)(\d{4})( \d{2}:\d{2})/;
        const res = regex.exec(ticket["Intervention planifiée"])
        if(res==undefined) return
        let filledDateLocal = new Date(res[3]+res[2]+res[1]+res[4])
        setFilledDate(filledDateLocal)
        setYear(filledDateLocal.getFullYear())
        setMonth([filledDateLocal.getMonth(),"init"])
        setWeekNum(getWeekNumber(filledDateLocal)[1])
        setSelectedDate(filledDateLocal)
    },[ticket["Intervention planifiée"]])

    const API_KEY = "AIzaSyCYe_5JdxOzwjZHMWYlA3xXZSKNfan47zI"
    const CLIENT_ID = "873600791988-q2mmgc00g2vaj9k1k9rurqs8ecf44alg.apps.googleusercontent.com"
    const SCOPES = "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar";
    //const SCOPES = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar https://www.google.com/calendar/feeds/ https://www.google.com/calendar/feeds http://www.google.com/calendar/feeds/default/allcalendars/full https://www.google.com/calendar/feeds/default/owncalendars/full http://www.google.com/calendar/feeds/ http://www.google.com/calendar/feeds https://www.google.com/calendar/feeds/default/private/full http://www.google.com/calendar/feeds/default/private/full http://www.google.com/calendar/feeds/default/owncalendars/full/ https://www.google.com/calendar/feeds/default https://www.google.com/calendar/freebusy https://www.googleapis.com/auth/calendar.calendarlist https://www.googleapis.com/auth/calendar.calendarlist.readonly https://www.googleapis.com/auth/calendar.readonly";
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.src = "https://apis.google.com/js/api.js";

        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.gapi) handleClientLoad();
            else setMessageErreur("Script gapi introuvable")
        });
    }, []);



    const handleClientLoad = () => {
        window.gapi.load("client:auth2", initClient);
    };  
     const initClient = () => {
        window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
            clientId: CLIENT_ID,
            scope: SCOPES
        }).then(res=> {
            window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

            updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
        }).catch((erreur)=>{
            setMessageErreur("Erreur lors de l'init gapi client : "+erreur?.details)
            console.error("Erreur lors de l'init gapi client",erreur)
        })
    }; 
    const updateSigninStatus=(isSignedIn)=>{
        if(isSignedIn){
            GAPI_getCalendarsList()
        }else{
            setMessageErreur("Non connecté à un compte Google")
            window.gapi.auth2.getAuthInstance().signIn() 
        }
    }

    const handleClickReloginGoogle=()=>{
        window.gapi.auth2.getAuthInstance().signIn() 
    }
    const handleLogoutGoogle=()=>{
        window.gapi.auth2.getAuthInstance().signOut() 
    }
    /*const openSignInPopup = () => {
        window.gapi.auth2.authorize({ client_id: CLIENT_ID, scope: SCOPES },
            (res) => {
                if (res) {
                if (res.access_token)
                    localStorage.setItem("access_token", res.access_token);

                // Load calendar events after authentication
                //window.gapi.client.load("calendar", "v3", listUpcomingEvents);
                }
            }
            );
     }  */
     
     const GAPI_getCalendarsList=()=>{
         window.gapi.client.calendar.calendarList.list()
         .then(function (response) {
             setCalendars(response.result.items)
             console.log("calendars",response.result.items)
         }).catch((erreur)=>{
            setMessageErreur("Erreur lors de la récupération de calendriers")
            console.error("Erreur lors de la récupération de calendriers",erreur)
         });
     }
    useEffect(() => {
        console.log("tech ou calendar changed")
        if(!technicien) return 
        if(!calendars.length) return
        let calUser = calendars.find(c=>c.summary==="GGM "+technicien.prenom/*technicien.name*/)
        setCalendarUser(calUser)
        if(!calUser){
            setMessageErreur('Le calendrier "GGM '+technicien.prenom+'" n\'est pas accessible')
            return
        }else{
            setMessageErreur("")
            let oneWeekAgo = new Date()
            oneWeekAgo.setDate(oneWeekAgo.getDate()-7)
            let timeMin = oneWeekAgo
            if(!!filledDate){
                timeMin = filledDate<oneWeekAgo ? filledDate : oneWeekAgo
            }
            let inSixMonths = new Date()
            inSixMonths.setMonth(inSixMonths.getMonth()+6)
            window.gapi.client.calendar.events.list({
                // Fetch events from user's primary calendar
                calendarId: calUser.id,
                singleEvents: true,
                timeMin: timeMin.toISOString(),
                timeMax: inSixMonths.toISOString()
            }).then(function (response) {
                let tmp = []
                console.log("events before",response.result.items)
                response.result.items.map(e=>{
                    let debut = new Date(e.start.dateTime)
                    let fin = new Date(e.end.dateTime)
                    let obj = {id:e.id, titre:e.summary, debut:debut, fin:fin, ou:e.location, ticket:e.source?.title||"", duree:(fin-debut)/(1000*60*60)}
                    if(!!filledDate && e.source?.title===ticket.identifiant){
                        obj.deletable = true
                    }
                    tmp.push(obj)
                })
                setEvents(tmp)
            })
        } 
    }, [technicien, calendars]);

    useEffect(()=>{
        if(!events) return
        console.log("events",events)
        if(filledDate){

        }
    },[events])


    useEffect(()=>{
        if(month[1]=="init")return
        let tmp = getWeekNumber(new Date(year,month[0],4))
        setWeekNum(tmp[1])
    },[month])

    useEffect(()=>{
        setWeek(getWeekDaysFromMonday(getDateOfISOWeek(weekNum, year)))
    },[weekNum]) 

    const changeMonth = (add)=>{
        let d = new Date(year,month[0],1)
        d.setMonth(month[0]+add)
        setYear(d.getFullYear())
        setMonth([d.getMonth(),"ok"])
    }
    const changeWeekNum = (add)=>{
        let d = getDateOfISOWeek(weekNum+add, year)
        let tmp = getWeekNumber(d)
        setWeekNum(tmp[1])
        setYear(tmp[0])
        d.setDate(d.getDate()+3)
        setMonth([d.getMonth(),"init"])
    }

    const handleFocus=(event)=>{
        setOpen(true)
    }
    const handleClickAway=()=>{
        setOpen(false)
    }

    useEffect(()=>{
        if(!selectedDate || !heureDebut) return 
        selectedDate.setHours(heureFin.getHours())
        selectedDate.setMinutes(heureFin.getMinutes())
        selectedDate.setSeconds(0)
        setHeureFin(new Date(selectedDate.getTime()))
        selectedDate.setHours(heureDebut.getHours())
        selectedDate.setMinutes(heureDebut.getMinutes())
        setHeureDebut(new Date(selectedDate.getTime()))
    },[selectedDate])

    useEffect(()=>{
        if(!heureDebut) return
        console.log("heuredebut",heureDebut)
        let tmp=new Date(heureDebut.getTime())
        tmp.setHours(tmp.getHours()+1)
        if(tmp.getHours()+":"+tmp.getMinutes()===heureFin.getHours()+":"+heureFin.getMinutes()) return
        setHeureFin(tmp)
    },[heureDebut])

    const handleAjouter=()=>{
        //let calUser = calendars.find(c=>c.summary==="GGM "+technicien.prenom/*technicien.name*/)
        if(calendarUser===undefined){
            console.error(`Le calendrier "GGM ${technicien.prenom}" n'est pas accessible`)
            return
        }

        let datetimeStr = heureDebut.toLocaleDateString() + " " + heureDebut.toLocaleTimeString().slice(0,-3)
        setInputValue(datetimeStr)

        let titre = "SAV - " + ticket.prenom + " " + ticket.nom.toUpperCase()
        var request = window.gapi.client.calendar.events.insert({
            calendarId: calendarUser.id,
            resource:{
                summary: titre,
                location: ticket.ville,
                source:{
                    title: ticket.identifiant,
                    url:"https://www.ggm-fermetures.fr"
                },
                start: {'dateTime': heureDebut.toISOString()},
                end: {'dateTime': heureFin.toISOString()},
            }
        })
        request.execute(function(event) {
            console.log('Event created', event);
            setFilledDate(heureDebut)
            setEvents(currVal=>[...currVal, {id:event.id,titre:titre, debut:heureDebut, fin:heureFin, ou:ticket.ville, ticket:ticket.identifiant, deletable:true}])
            onChange(datetimeStr)
            setOpen(false)
        })
    }

    const handleDeleteEvent = (eventId)=>{
        if(eventId===null){
            setInputValue("")
            setFilledDate(undefined)
            onChange("")
            return
        }

        if(calendarUser===undefined){
            console.error(`Le calendrier "GGM ${technicien.prenom}" n'est pas accessible`)
            return
        }

        var request = window.gapi.client.calendar.events.delete({
            calendarId: calendarUser.id,
            eventId: eventId
        })
        request.execute(function(response) {
            console.log('Event deleted', response);
            setEvents(currVal=>currVal.filter(e=>e.id!=eventId))
            setInputValue("")
            setFilledDate(undefined)
            onChange("")
        })
    }

    return(
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: 'relative' }}>
                <input onFocus={handleFocus} disabled={disabled} value={inputValue} onChange={()=>{}}/>
                {open ? (
                    <Box sx={{position: 'absolute',top: 28, left:-300, p:2, zIndex: 1, bgcolor: 'background.paper', color:"primary.main", width:600, textAlign:"center", borderRadius:"20px", boxShadow:"5px 5px 5px gray"}}>
                    <MessageErreur technicien={technicien} messageErreur={messageErreur} onClickRelogin={handleClickReloginGoogle} onClickLogout={handleLogoutGoogle}/>
                    {technicien && !messageErreur && (
                        <>
                            <Box sx={{width:550, m:"auto",}}>
                                <IconButton disabled={!!filledDate} onClick={()=>changeMonth(-1)}><ArrowLeft/></IconButton>
                                {getMonthName(month[0]) +" " + year}
                                <IconButton disabled={!!filledDate} onClick={()=>changeMonth(1)}><ArrowRight/></IconButton>
                                <Box display="grid" gridTemplateColumns='repeat(8, 1fr)'> 
                                    <IconButton disabled={!!filledDate} onClick={()=>changeWeekNum(1)} style={{height: 20}}><ArrowDropUp/></IconButton>
                                    <span>L</span>
                                    <span>M</span>
                                    <span>M</span>
                                    <span>J</span>
                                    <span>V</span>
                                    <span>S</span>
                                    <span>D</span>

                                    <span>S{weekNum}</span>
                                    {week.map((date,i)=>{
                                        return <span key={i} onClick={()=>!filledDate && setSelectedDate(date)} style={{border: "solid 1px gray", cursor:"pointer", backgroundColor:Date_isEqual(date,selectedDate)?theme.palette.gris.contour:'transparent'}}>
                                            {date.getDate()}
                                            {!!events.filter(e=>Date_isEqual(e.debut,date)).length && 
                                                <Circle sx={{width:8, height:12,display:"block",m:"auto"}}/>
                                            }
                                        </span>
                                    })}
                                    <IconButton disabled={!!filledDate} onClick={()=>changeWeekNum(-1)} style={{height: 20, marginTop:-12}}><ArrowDropDown/></IconButton>
                                </Box>
                            </Box>
                            {Date_isInWeek(selectedDate, week) && (
                                <>
                                    {!events.filter(e=>Date_isEqual(e.debut,selectedDate)).length ? (
                                        <Typography>Aucun rendez-vous ce jour-là</Typography>
                                    ):(
                                        <Box sx={{border:"1px solid gray", my:2, p:2, height:150, overflow:"auto"}}>
                                            {events.filter(e=>Date_isEqual(e.debut,selectedDate)).sort(function(a,b){return a.debut - b.debut}).map(e=>
                                                <Box key={e.id} sx={{bgcolor:"background.main",borderRadius:"10px", display:"flex", px:3, border:'1px solid',borderColor:"gris.contour",my:0.5}}>
                                                    <Box sx={{textAlign:"left"}}>
                                                        <Typography>{e.titre}</Typography>
                                                        <Typography sx={{fontStyle:"italic"}}>{e.ou||""}</Typography>
                                                    </Box>
                                                    <Box sx={{ml:"auto", textAlign:"right"}}>
                                                        <Typography>{e.debut.toLocaleTimeString().slice(0,-3)}</Typography>
                                                        <Typography>{e.fin.toLocaleTimeString().slice(0,-3)}</Typography>
                                                        {e.deletable &&
                                                            <Typography sx={{fontSize:10,color:"red",cursor:"pointer"}} onClick={()=>handleDeleteEvent(e.id)}>Supprimer le rendez-vous</Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                            )}                                            
                                        </Box>
                                    )}
                                    {filledDate && events.filter(e=>e.source?.title===ticket.identifiant).length == 0 &&
                                        <Typography sx={{fontSize:10,color:"red",cursor:"pointer"}} onClick={()=>handleDeleteEvent(null)}>Supprimer le rendez-vous</Typography>
                                    }
                                    {!filledDate && 
                                    <Box sx={{display:"flex", justifyContent: "space-between", borderRadius:"20px", border:"2px solid gray", mx:4,p:2}}>
                                        <Box>
                                            <span>{ticket.prenom} {ticket.nom.toUpperCase()}</span>
                                            <span style={{display:"block", textAlign:"initial", fontStyle:"italic"}}><Room sx={{position: "relative", top: "5px"}}/>{ticket.ville}</span>
                                        </Box>
                                        <Box>
                                            <Box sx={{display: "flex", width:200, justifyContent: "space-between"}}>
                                                <Typography sx={{color:"gris.contour"}}>Début</Typography>
                                                <TimePicker value={heureDebut} onChange={setHeureDebut}/>
                                            </Box>
                                            <Box sx={{display: "flex", width:200, justifyContent: "space-between"}}>
                                                <Typography sx={{color:"gris.contour"}}>Fin</Typography>
                                                <TimePicker value={heureFin} onChange={setHeureFin} min={new Date(heureDebut.getTime())}/>
                                            </Box>
                                            {/*<LocalizationProvider dateAdapter={DateAdapter} locale={frLocale}>
                                                <Box sx={{display: "flex", width:200, justifyContent: "space-between"}}>
                                                    <Typography sx={{color:"gris.contour"}}>Début</Typography>
                                                    <TimePicker value={heureDebut} onChange={setHeureDebut}
                                                        shouldDisableTime={(timeValue, clockType) => {
                                                            if (clockType === 'minutes' && timeValue % 5)  return true
                                                            return false
                                                        }}
                                                        renderInput={(params) => <TextField sx={{width:102,"& .MuiOutlinedInput-input":{py:0}}} {...params} />}
                                                    />
                                                </Box>
                                                <Box sx={{display: "flex", width:200, justifyContent: "space-between"}}>
                                                    <Typography sx={{color:"gris.contour"}}>Fin</Typography>
                                                    <TimePicker value={heureFin} minTime={heureDebut} onChange={setHeureFin}
                                                        shouldDisableTime={(timeValue, clockType) => {
                                                            if (clockType === 'minutes' && timeValue % 5)  return true
                                                            return false
                                                        }}
                                                        renderInput={(params) => <TextField sx={{width:102,"& .MuiOutlinedInput-input":{py:0}}} {...params} />}
                                                    />
                                                </Box>
                                            </LocalizationProvider>*/}
                                            <Button variant='contained' sx={{ml:"auto", mt:1, display:"block"}} onClick={handleAjouter}>Ajouter ce jour-là</Button>
                                        </Box>
                                    </Box>
                                    }
                                </>
                            )}
                        </>
                    )}
                    </Box>
                ): null}
            </Box>
        </ClickAwayListener>
    )
}


function MessageErreur({technicien, messageErreur,onClickRelogin, onClickLogout}){
    const isLocalhost = window.location.href.includes("localhost")
    const showDeconnexion = isLocalhost || messageErreur==="Erreur lors de la récupération de calendriers"

    return (
        <>
            {!technicien && (<Typography>Veuillez sélectionner un technicien pour accéder à son agenda</Typography>)}
            {messageErreur && (<Typography>{messageErreur}</Typography>)}
            {messageErreur==="Non connecté à un compte Google" && (<Button variant='contained' onClick={onClickRelogin}>Relancer connexion Google</Button>)}
            {showDeconnexion && <Button onClick={onClickLogout}>Déconnexion Google</Button>}
        </>
    )
}