import { Circle, MoreVert, Delete } from '@mui/icons-material';
import { Typography, Box, IconButton, Popover, Button } from "@mui/material";
import { DataGrid, frFR } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../services/UserContext"
import API from "../../services/API";
import { getDate, set } from "date-fns";
import { Date_localeStringToDate, getSQLDatetimeNow } from "../../services/DateHelper";

const statutDateSortComparator = (v1, v2, param1, param2) => {
    if(!!v1.dateInter && !v2.dateInter){
        return 1
    }else if(!v1.dateInter && !!v2.dateInter){
        return -1
    }else if(!!v1.dateInter){
        let tmp1 = Date_localeStringToDate(v1.dateInter)
        let tmp2 = Date_localeStringToDate(v2.dateInter)
        return Date_localeStringToDate(v1.dateInter).getDate() - Date_localeStringToDate(v2.dateInter).getDate()
    }else{
        let tmp1 = new Date(v1.dateCreation)
        let tmp2 = new Date(v2.dateCreation)
        return new Date(v2.dateCreation).getDate() - new Date(v1.dateCreation).getDate()
    }
  };

export default function Tickets({}){
    const [userContext, setUserContext] = useContext(UserContext)
    const [tickets, setTickets] = useState([])
    const [techniciens, setTechniciens] = useState([])
    const [anchorEl, setAnchorEl] = useState(undefined)
    const [interventionIdSelected, setInterventionIdSelected] = useState("")
    const [openPopoverRow, setOpenPopoverRow] = useState(false)
    let navigate = useNavigate();

    const colorsCircle ={"Brouillon":"red",
        "En attente":"#7030a0",
        "En attente de pièces":"#ffd966",
        "Planifié":"#00b0f0",
        "En cours d'intervention":"#8faadc",
        "Annulé":"#c00000",
        "Intervention terminée":"#a9d18e"}

    let columns = [
        { field: 'id', headerName: 'id', flex:1, hide:true},
        { field: 'identifiant', headerName: 'Numéro', flex:1, hide:true},
        { field: 'Statut modifié', headerName: 'Statut', flex:1,
            renderCell: (params) => {
                return <><Circle sx={{ color: colorsCircle[params.value], width:"12px",mr:0.5}}/>{params.value}</>
            }
        },
        { field: 'client', headerName: 'Client', flex:1},
        { field: 'ville', headerName: 'Ville', flex:1},
        { field: 'Technicien attribué', headerName: 'Attribué à', flex:1,
            valueGetter:(params)=>{
                let tech = techniciens.find(t=>t.id==params.row['Technicien attribué'])
                return tech?tech.prenom + " " + tech.nom:params.row['Technicien attribué']
            }
        },
        { field: 'date', headerName: 'Date', flex:1,
            valueGetter:(params)=>{
                return({
                    dateInter:params.row['Intervention planifiée'],
                    dateCreation:params.row.dateCreation,
                    statut:params.row['Statut modifié']
                })
            },
            valueFormatter: (params) => {
                const value = params.value;
                if (value.dateInter) {
                    return value.dateInter
                    return new Date(value.dateInter).toLocaleDateString() + " "+ new Date(value.dateInter).toLocaleTimeString().slice(0,-3)
                }else{
                    return new Date(value.dateCreation.replace(/-/g, "/")).toLocaleDateString() 
                }
              },
            sortComparator: statutDateSortComparator, 
        }
    ];

    if(userContext.role==="admin"){
        columns.push(
            {field: 'action', headerName: '', sortable: false, flex:0.2, hide:userContext.role!=="admin",
                renderCell: (params) => {
                    const onClick = (e)=>{
                        e.stopPropagation(); // don't select this row after clicking
                        setAnchorEl(e.currentTarget)
                        openRowOptions(params.row.id);
                    }
                    return  <IconButton color="primary" onClick={onClick}><MoreVert /></IconButton>
                },
            }
        )
    }
    
    useEffect(()=>{
        if(!userContext.prenom) return
        API("GET","utilisateur")
        .then(res=>setTechniciens(res.json))

        let statutsAffiches = ["En attente","En attente de pièces","Planifié","En cours d'intervention"]
        API("GET","ticket",{},{statut:statutsAffiches})
        .then(res=>{
            let ticketsTmp = res.json//.filter(t=>statutsAffiches.includes(t["Statut modifié"]))
            if(userContext.role!=="admin"){
                let statutsRestreints = ["Planifié","En cours d'intervention"]
                ticketsTmp = ticketsTmp.filter(t=>!statutsRestreints.includes(t["Statut modifié"]) || t['Technicien attribué']*1===userContext.id)
            }
            
            setTickets(ticketsTmp)
        })
    },[userContext])

    const openRowOptions = (interId) =>{
        setInterventionIdSelected(interId)
        setOpenPopoverRow(true)
    }

    const handleClosePopover = () => {
        setOpenPopoverRow(false)
        setAnchorEl(null);
      };

    const handleDeleteInter = ()=>{
        handleClosePopover()
        /*API("POST","logticket",{Ticket_id:interventionIdSelected, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Statut modifié", donnee:"Annulé"})
        .then(res=>{
            let ticketsTmp = tickets
            setTickets(ticketsTmp.filter(t=>t.id!=interventionIdSelected))
        })*/
        API("DELETE","ticket",{id:interventionIdSelected})
        .then(res=>{
            if(res.ok){
                setTickets(tickets.filter(t=>t.id!=interventionIdSelected))
            }else{
                alert("Une erreur s'est produite lors de la suppression du ticket. Veuillez contacter l'administrateur")
                console.warn("Erreur suppression ticket",res)
            }
        })
    }

    return(
        <>
            <Typography variant="h4" sx={{padding:"30px"}}>Management des tickets</Typography>
            <Box sx={{display:"flex",justifyContent: 'space-around',bgcolor:"white", border:"1px solid", borderColor:"gris.contour", borderRadius:"10px"}}>
                <Compteur titre="Brouillon" nombre={tickets.filter(t=>t["Statut modifié"]=="Brouillon").length}/>
                <Compteur titre="En attente" nombre={tickets.filter(t=>t["Statut modifié"]=="En attente").length}/>
                <Compteur titre="En attente de pièces" nombre={tickets.filter(t=>t["Statut modifié"]=="En attente de pièces").length}/>
                <Compteur titre="En cours" nombre={tickets.filter(t=>t["Statut modifié"]=="En cours d'intervention").length}/>
            </Box>

            <Popover open={openPopoverRow} anchorEl={anchorEl} onClose={handleClosePopover}>
                <Button color="primary" onClick={handleDeleteInter}><Delete /> Supprimer ce ticket</Button>
            </Popover>

            <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                rows={tickets}
                rowHeight={40}
                autoHeight
                disableSelectionOnClick 
                onRowClick={(params, event) => {
                    navigate("ticket/"+params.id)
                }}
                initialState={{
                    sorting: {
                        sortModel: [{
                            field: 'date',
                            sort: 'desc',
                        }],
                    },
                }}
                sx={{bgcolor:"white", mt:"20px", border:"1px solid",borderColor:"gris.contour", borderRadius:0,
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid gray`,
                    },
                    '& .MuiDataGrid-columnHeaders':{
                        fontSize:"1.1rem",
                        fontWeight:600
                    },
                    '& .MuiDataGrid-cell[data-field="date"]':{
                        whiteSpace:"initial"
                    }
                }}
            />
        </>
    )
}

function Compteur({titre, nombre}){
    return(
        <div style={{width:"fit-content"}}>
            <Typography sx={{color:"gris.contour",fontSize:"0.8rem"}}>{titre}</Typography>
            <Typography sx={{textAlign:"center"}}>{nombre}</Typography>
        </div>
    )
}