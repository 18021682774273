import { AppBar, Toolbar, IconButton, Typography, Button, Box, Modal, CircularProgress, Popover} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useParams} from "react-router-dom";
import { useEffect, useState, useContext, useCallback, useRef } from "react";
import Dropdown from "../../components/Dropdown"
import Dropdown_Statut from "../../components/Dropdown_Statut"
import DatePicker from "../../components/DatePicker"
import Textarea from "../../components/Textarea"
import API from "../../services/API";
import FormulaireTicket from "../../components/FormulaireTicket";
import { getSQLDatetimeNow } from "../../services/DateHelper"
import { UserContext } from "../../services/UserContext"
import SignaturePad from 'react-signature-pad-wrapper'
import CheckboxItem from "../../components/CheckboxItem";

const initStatuts = [{id:"Brouillon", name:"Brouillon", color:"orange"},
{id:"En attente", name:"En attente", color:"#7030a0"},
{id:"En attente de pièces", name:"En attente de pièces", color:"#ffd966"},
{id:"Planifié", name:"Planifié", color:"#00b0f0", disabled:true},
{id:"En cours d'intervention", name:"En cours d'intervention", color:"#8faadc", disabled:true},
{id:"Intervention terminée", name:"Intervention terminée", color:"#a9d18e", disabled:true},
{id:"Annulé", name:"Annulé", color:"#c00000"},
{id:"Clôturé", name:"Clôturé", color:"#c00000", disabled:true}]

export default function Ticket({}){
    const [userContext, setUserContext] = useContext(UserContext)
    const [ticket, setTicket] = useState({})
    const [client, setClient] = useState({})
    const [logsTicket, setLogsTicket] = useState([])
    const [utilisateurs, setUtilisateurs] = useState([{id:0,name:""}])
    const [disabled, setDisabled] = useState({techicien:false, dateInter: false})
    const [logsChanged, setLogsChanged] = useState(true)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupStop, setOpenPopupStop] = useState(false)
    const [reloadDefVal, setReloadDefValStatut] = useState(0)
    const [donnePopupSaved, setDonnePopupSaved] = useState("")
    const [statuts, setStatuts] = useState(initStatuts)
    const [processing, setProcessing] = useState(false)

    const [answerComment, setAnswerComment] = useState("")


    let { id } = useParams();

    useEffect(()=>{

        if(Object.keys(ticket).length==0){
            setProcessing(true)
        }
        API("GET","ticket",{},{id:id})
        .then(res=>{ 
            console.log(res.json) 
            setTicket(res.json[0])
            
            
            if(!res.json[0].Client_id){
                setProcessing(false)
                return
            }
            API("GET","client",{},{id:res.json[0].Client_id})
            .then(res2=>{
                setClient(res2.json[0])
                setProcessing(false)
            })
        })
        API("GET","logticket",{},{Ticket_id:id})
        .then(res=>setLogsTicket(res.json))

        API("GET","utilisateur")
        .then(res=>{
            res.json.map(r=>r.name=r.prenom.capitalize() + " " + r.nom.toUpperCase())
            setUtilisateurs(res.json)
        })
    },[])

    useEffect(()=>{
        switch(ticket['Statut modifié']){
            case "Brouillon":
                break
            case "En attente":
                let temp = initStatuts
                temp.find(c=>c.id==="Brouillon").disabled=true
                setStatuts(temp)
                break
            case "En attente de pièces":
                setStatuts(currVal=>{
                    currVal.find(c=>c.id==="Brouillon").disabled=true
                    return currVal
                })
                break
            case "Planifié":
                setStatuts(currVal=>{
                    currVal.filter(c=>c.id!=="Planifié").map(c=>c.disabled=true)
                    return currVal
                })
                break
            case "En cours d'intervention":
                setStatuts(currVal=>{
                    currVal.find(c=>c.id==="Brouillon").disabled=true
                    return currVal
                })
                break
            case "Intervention terminée":
                setStatuts(currVal=>{
                    currVal.find(c=>c.id==="Brouillon").disabled=true
                    return currVal
                })
                break
            case "Annulé":
                setStatuts(currVal=>{
                    currVal.find(c=>c.id==="Brouillon").disabled=true
                    return currVal
                })
                break
            case "Clôturé":
                setStatuts(currVal=>{
                    currVal.filter(c=>c.id!=="Clôturé").map(c=>c.disabled=true)
                    return currVal
                })
                break
        }
        /*if(ticket['Statut modifié']==="Clôturé"){
            setStatuts(currVal=>{
                currVal.filter(c=>c.id!=="Clôturé").map(c=>c.disabled=true)
                return currVal
            })
        }else if(ticket['Statut modifié']!=="Brouillon"){
            setStatuts(currVal=>{
                currVal.find(c=>c.id==="Brouillon").disabled=true
                return currVal
            })
        }*/
    },[ticket['Statut modifié']])

    useEffect(()=>{
        if(!logsChanged) return 
        setLogsChanged(false)

        API("GET","logticket",{},{Ticket_id:id})
        .then(res=>setLogsTicket(res.json))
    },[logsChanged])

    const clickIntervention=(state)=>{
        let statut = ""
        let inter = ""
        let now = getSQLDatetimeNow()
        if(state == "Demarrer"){
            statut="En cours d'intervention"
            inter="Intervention démarrée"
            setTicket(currVal=>({...currVal, ["Statut modifié"]:statut, ["Statut intervention"]:inter}))
            API("POST","logticket",[
                {Ticket_id:id, date:now, Qui:userContext.id, quoi:"Statut modifié", donnee:statut},
                {Ticket_id:id, date:now, Qui:userContext.id, quoi:inter}])
            .then(res=> setLogsChanged(true))
        }else{  
            setOpenPopupStop(true)
        }
        
    }
    const handleChange=(key, value)=>{
        if(key=="Statut modifié" && ticket["Statut modifié"]==="Brouillon"){
            setDonnePopupSaved(value)
            setOpenPopup(true)
            return
        }
        let d = getSQLDatetimeNow()
        let logs=[]
        let tmp = {}
        if(key==="Intervention planifiée"){
            if(value===""){ // Planification supprimée
                logs.push({Ticket_id:ticket.id, date:d, Qui:userContext.id, quoi:"Statut modifié", donnee:"En attente"})
                tmp["Statut modifié"] = "En attente"
            }else{
                logs.push({Ticket_id:ticket.id, date:d, Qui:userContext.id, quoi:"Statut modifié", donnee:"Planifié"})
                tmp["Statut modifié"] = "Planifié"
            }
        }
        
        setTicket(currVal=>({...currVal, [key]:value, ...tmp}))
        logs.push({Ticket_id:id, date:d, Qui:userContext.id, quoi:key, donnee:value})
        API("POST","logticket",logs)
        .then(res=> setLogsChanged(true))
    }

    const callbackForm = (val, ticketIdentifiant="")=>{
        if(val===false){
            setReloadDefValStatut(reloadDefVal+1) // réinitialisation du dropdown statut
        }else{
            setTicket(currVal=>({...currVal, ["Statut modifié"]:donnePopupSaved, identifiant:ticketIdentifiant}))
            API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Statut modifié", donnee:donnePopupSaved})
            .then(res=>setLogsChanged(true))
        } 
        setOpenPopup(false)
    }


    const handleChangeComment=(value)=>{
        setAnswerComment(value)
    }
    const handleComment=()=>{
        if(!answerComment) return
        let tmp = answerComment
        setAnswerComment("")

        API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Commentaire", donnee:tmp})
        .then(res=>setLogsChanged(true))
    }

    const handleStop=(signature, comment)=>{
        let now = getSQLDatetimeNow()
        let statut="Intervention terminée"
        let inter="Intervention terminée"
        setTicket(currVal=>({...currVal, ["Statut modifié"]:statut, ["Statut intervention"]:inter}))
        let logs = [{Ticket_id:id, date:now, Qui:userContext.id, quoi:"Statut modifié", donnee:statut},
                    {Ticket_id:id, date:now, Qui:userContext.id, quoi:inter, donnee:signature}]
        if(comment!==""){
            logs.push({Ticket_id:id, date:now, Qui:userContext.id, quoi:"Commentaire", donnee:comment})
        }
        API("POST","logticket",logs)
        .then(res=>setLogsChanged(true))

    }

    const handleCloture=(facturation)=>{
        let promises=[]
        setTicket(currVal=>({...currVal, ["Statut modifié"]:"Clôturé"}))
        
        promises.push(API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Statut modifié", donnee:"Clôturé"}))
        promises.push(API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Ticket clôturé", donnee:facturation}))
        Promise.all(promises).then(allRes=>{
            //if(allRes[0].ok && allRes[1].ok)
            //console.log(allRes)
            setLogsChanged(true)
        })
    }

    const handleReOpen=()=>{
        let promises=[]
        setTicket(currVal=>({...currVal, ["Statut modifié"]:"En attente", ["Technicien attribué"]:"", ["Intervention planifiée"]:""}))
        
        promises.push(API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Statut modifié", donnee:"En attente"}))
        promises.push(API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Technicien attribué", donnee:""}))
        promises.push(API("POST","logticket",{Ticket_id:id, date:getSQLDatetimeNow(), Qui:userContext.id, quoi:"Intervention planifiée", donnee:""}))
        Promise.all(promises).then(allRes=>{
            setLogsChanged(true)
        })
    }

    //const interDemarree = ticket && ticket["Statut intervention"]==="Intervention démarrée"
    //const interTerminee = ticket && ticket["Statut intervention"]==="Intervention terminée"
    const interDemarree = ticket && ticket["Statut modifié"]==="En cours d'intervention"
    const interTerminee = ticket && ticket["Statut modifié"]==="Intervention terminée"
    const ticketCloture = ticket && ticket["Statut modifié"]==="Clôturé"
    const brouillon = ticket && ticket["Statut modifié"]==="Brouillon"
    const planifie = ticket && ticket["Statut modifié"]==="Planifié"

    return(
        <>
            {processing &&
                <CircularProgress  sx={{display:"block",m:"auto"}}/>
            }
            {!processing &&
            <>
                <AppBar position="sticky" sx={{bgcolor:"background.main", boxShadow:"unset"}}>
                    <Toolbar>
                        <Box sx={{display:"grid", gridTemplateColumns:'repeat(4, 1fr)', gap:1, alignItems: "center"}}>
                            <div>
                                <Typography color="primary" sx={{textAlign:"center", fontWeight:700}}>{ticket.identifiant ||"-"}</Typography>
                                {interDemarree && !ticketCloture &&
                                    <Button color="secondary" variant="contained" onClick={()=>clickIntervention("Arreter")} sx={{fontSize:"0.6rem", fontWeight:100, textTransform:"none",paddingX:"5px", display:"block", m:"auto"}} >Terminer l'intervention</Button>
                                }{!interDemarree && !ticketCloture &&
                                    <Button color="primary" variant="contained" disabled={!ticket["Technicien attribué"]||!ticket["Intervention planifiée"]} onClick={()=>clickIntervention("Demarrer")} sx={{fontSize:"0.6rem", fontWeight:100, textTransform:"none",paddingX:"5px", display:"block", m:"auto"}} >Démarrer l'intervention</Button>
                                }
                            </div>
                            <div>
                                <Typography sx={{color:"gris.contour", textAlign:"center"}}>Technicien</Typography>
                                <Dropdown options={utilisateurs} defaultValue={ticket["Technicien attribué"]||""} disabled={brouillon||planifie||interDemarree||interTerminee||ticketCloture} onChange={(v)=>handleChange("Technicien attribué",v)} inputProps={{sx:{bgcolor:"white","& .MuiSelect-select":{py:0}}}}/>
                            </div>
                            <div>
                                <Typography sx={{color:"gris.contour", textAlign:"center"}}>Date d'intervention</Typography>
                                <DatePicker technicien={utilisateurs.find(u=>u.id==ticket["Technicien attribué"])}  disabled={brouillon||interDemarree||interTerminee||ticketCloture} ticket={ticket} onChange={(v)=>handleChange("Intervention planifiée",v)}/>
                            </div> 
                            <Dropdown_Statut options={statuts} defaultValue={ticket["Statut modifié"]||"Brouillon"} disabled={interDemarree} reloadDefVal={reloadDefVal} onChange={(v)=>handleChange("Statut modifié",v)}/>
                        </Box>
                    </Toolbar>
                    {ticket["Statut modifié"]==="Intervention terminée" && !ticketCloture && userContext.role==="admin" &&
                        <Box sx={{display:"flex", justifyContent: "space-around", m:2}}>
                            <Button variant="outlined" onClick={()=>handleCloture("Sans facturation")}>Clôturer sans facturer</Button>
                            <Button variant="contained" onClick={()=>handleCloture("Avec facturation")}>Clôturer et facturer</Button>
                        </Box>
                    }
                </AppBar>

                <FormulaireTicket admin={true} data={{ticket:ticket, client:client}} openPopup={openPopup} callback={callbackForm} disabled={interDemarree||interTerminee}/>

                {logsTicket.map(log=>(
                    <LogLine key={log.id} data={log} users={utilisateurs}/>
                ))}

                <Textarea name="Commentaire" required={true} defaultValue={answerComment} disabled={false/*interDemarree||interTerminee*/} onChange={handleChangeComment} nbLines={4} style={{width:"100%", mt:"20px", "&>div":{py:"5px", backgroundColor:"white"} }}  />
                <Button color="primary" variant="contained" onClick={handleComment} disabled={!answerComment||interDemarree||interTerminee} style={{display: "block", marginLeft: "auto", marginRight: 0, marginTop: 15, textTransform:"none"}}>Commenter</Button>

                {ticketCloture && 
                    <Box sx={{display:"flex", justifyContent: "space-around", m:2}}>
                        <Button variant="contained" onClick={handleReOpen}>Ré-ouvrir</Button>
                    </Box>
                }
                <PopupStop open={openPopupStop} setOpen={setOpenPopupStop} onChange={handleStop} />
            </>
            }
            
        </>
    )
}

function LogLine({data, users}){
    const [anchorEl, setAnchorEl] = useState(null);

    const user_idToName = (id)=>{
        let user = users.find(u=>u.id==id)
        return user?user.name:"" 
    }

    /*useEffect(()=>{
        if(data.quoi === "Technicien attribué"){
            data.donnee = user_idToName(data.donnee)
        }
    },[data])*/

    const handlePreuveClick=(ev)=>{
        setAnchorEl(ev.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
      };
      const open = Boolean(anchorEl);

    return(
        <Box sx={{my:"8px"}}>
            <Typography sx={{color:"gris.contour", fontStyle:"italic"}}>
                {data.quoi} {!!data.Qui?"par "+user_idToName(data.Qui):""} le {new Date(data.date).toLocaleDateString()} à {new Date(data.date).toLocaleTimeString().slice(0,-3)}
                {!!data.donnee?" : ":""}
                {data.quoi==="Intervention terminée" ? 
                    <>
                        <Button onClick={handlePreuveClick} sx={{textTransform:"none"}}>Preuve de clôture</Button>
                        <Popover open={open} onClose={handleClose} anchorEl={anchorEl}
                            anchorOrigin={{vertical: 'bottom',horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                        >
                            {data.donnee==="Client absent" ? <Box sx={{p:2}}>Client absent</Box>:<img src={data.donnee} width={200}/>}
                        </Popover>
                    </>
                : data.quoi!=="Commentaire"? (data.quoi==="Technicien attribué"?user_idToName(data.donnee) : data.donnee):""}
            </Typography>
            <>
                {data.quoi==="Commentaire" &&
                    <Typography sx={{color:"primary.main", border:"2px solid", borderColor:"gris.contour", borderRadius:"10px", mx:"10px", p:"10px"}}>
                        {data.donnee}
                    </Typography>
                }
            </>
        </Box>
    )
}


function PopupStop({open, setOpen, onChange}){
    const [userContext, setUserContext] = useContext(UserContext)
    const [commentaire, setCommentaire] = useState("")
    const [clientAbsent, setClientAbsent] = useState(false)
    const [erreur, setErreur] = useState(false)
    const [childref, setChildref] = useState()

    //const componentRef = useRef()
    //const { width, height } = useContainerDimensions(componentRef)
    //let childref
    

    const addRef = useCallback((r) => {
        //childref = r;
        setChildref(r)
    }, [childref]);
 
    const print = useCallback((r) => {
        childref.print();
    }, [childref]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        height: "80%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    /*useEffect(() => {
        window.addEventListener('resize', handleResize)
        return _ => {window.removeEventListener('resize', handleResize)}
    })*/
    /*useEffect(()=>{
        console.log("dims",width,height)
    },[width, height])*/

    const handleTerminer = ()=>{
        if(childref.isEmpty() && !clientAbsent){
            setErreur(true)
            return
        }
        onChange(clientAbsent?"Client absent":childref.toDataURL(), commentaire)

        setErreur(false)
        setCommentaire("")
        setOpen(false)
    }

    return(
        <Modal sx={{"& > .MuiBox-root":{height:"auto"}}} open={open||false} onClose={()=>setOpen(false)}>
            <>
                <Box sx={style}>
                    <Typography variant="h4">Fin d'intervention</Typography>
                    <Box sx={{height:"calc(100% - 75px)", display:"flex"}}> 
                        <Box /*ref={componentRef}*/ sx={{width:"50%",display:"inline-block", position:"relative", /*height:"calc(100% - 90px)",*/m:2,p:2.7,border:"1px solid gray", borderRadius:10}}>
                            <Typography sx={{position: "absolute", top: "-15px", left: "40px", bgcolor: "white", padding: "5px", fontSize: "13px", color:"gray"}}>Signature</Typography>
                            <SignaturePad ref={addRef} options={{penColor: 'black'}} />
                            <CheckboxItem name="Client absent" onChange={setClientAbsent} />
                            {erreur && <Typography color="secondary" >Le client doit signer</Typography>}
                        </Box>
                        <Textarea name="Commentaire éventuel" onChange={setCommentaire} style={{/*height:"calc(100% - 46.8px)",*/ width:"50%", m:2, "& .MuiOutlinedInput-root":{height:"100%", borderRadius:10}, "& .MuiInputLabel-root.Mui-focused":{transform: "translate(44px, -9px) scale(0.75)"}, "& legend":{ml:"30px"}}} InputProps={{sx:{height:"100% !important"}}}/>
                    </Box>
                    <Button variant="contained" color="secondary" onClick={handleTerminer} sx={{display:"block", ml:"auto"}}>Terminer</Button>
                </Box>
            </>
      </Modal>
    )
}

/*const useContainerDimensions = myRef => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })
  
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };*/

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.toLowerCase().slice(1)
}