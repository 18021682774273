import {Drawer, Typography, Container, Box, Button, Icon, IconButton, Menu, MenuItem} from '@mui/material';
import {BrowserRouter as Router, Redirect, Route, Routes, NavLink, Link, useMatch, useResolvedPath, resolvePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import {AddCircleOutline, Construction, SummarizeOutlined, SearchOutlined, ExitToApp, Person, AccountCircle} from '@mui/icons-material';
import Creer from './Admin/Creer';
import Tickets from './Admin/Tickets';
import Ticket from './Admin/Ticket';
import { useTheme } from '@mui/material/styles';
import Rapport from './Admin/Rapports';
import Recherche from './Admin/Recheche';
import { UserContext } from "../services/UserContext"
import { useContext, useEffect, useState } from 'react';
import API from '../services/API';
import Utilisateurs from './Admin/Utilisateurs';
import Utilisateur from './Admin/Utilisateur';


export default function Admin({}){
    const [userContext, setUserContext] = useContext(UserContext)
    const [anchorEl, setAnchorEl] = useState(null);
    let navigate = useNavigate();

    useEffect(()=>{
        if(!!userContext.prenom) return
        API("GET","utilisateur/me",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            if (res.ok) {
                setUserContext(currVal => ({...currVal, ...res.json[0] }))
            } else {
                if (res.status === 401) {
                  // Edge case: when the token has expired.
                  // This could happen if the refreshToken calls have failed due to network error or
                  // User has had the tab open from previous day and tries to click on the Fetch button
                  window.location.reload()
                } /*else {
                  setUserContext(oldValues => {
                    return { ...oldValues, details: null }
                  })
                }*/
              }
        })
    },[userContext])

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout=()=>{
        API("GET","utilisateur/logout",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            console.log("logout",res)
            setUserContext(currVal => ({ ...currVal, token: null }))

        })
    }

    return(
        <>
        <Drawer variant="permanent" anchor="left" sx={{width: 130, '& .MuiDrawer-paper': {width: 130,boxSizing: 'border-box', bgcolor:"primary.main"}}} >
            <img src="/logo.png" style={{width:80, margin:"20px auto"}}/>
            <Typography sx={{color:"white", textAlign:"center",mb:3}}>OMD</Typography>
            <CustomLink to="creer"><AddCircleOutline/><span style={{verticalAlign:"super"}}>Créer</span></CustomLink>
            <CustomLink to=""><Construction/><span style={{verticalAlign:"super"}}>Tickets</span></CustomLink>
            {userContext.role === "admin" && 
                <>
                    <CustomLink to="rapports"><SummarizeOutlined/><span style={{verticalAlign:"super"}}>Rapports</span></CustomLink>
                    <CustomLink to="recherche"><SearchOutlined/><span style={{verticalAlign:"super"}}>Recherche</span></CustomLink>
                    <CustomLink to="utilisateurs"><Person/><span style={{verticalAlign:"super"}}>Utilisateurs</span></CustomLink>
                </>
            }
            <Box sx={{mt:"auto", pt:"70px"}}>
                <Typography sx={{color:"gris.contour", fontSize:"0.85rem", textAlign:"center"}}>Bonjour {userContext.prenom}</Typography>
                <IconButton sx={{color:"gris.contour", display:"block", margin:"auto"}} onClick={handleMenu}><AccountCircle/></IconButton>
                <Menu anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} keepMounted
                    transformOrigin={{vertical: 'top',horizontal: 'center',}} open={Boolean(anchorEl)} onClose={handleClose}>
                    {/*<MenuItem onClick={handleClose}>Mon profil</MenuItem>*/}
                    <MenuItem onClick={()=>navigate("/admin/utilisateur/"+userContext.id)}><AccountCircle/>Profil</MenuItem>
                    <MenuItem onClick={handleLogout}><ExitToApp/>Déconnexion</MenuItem>
                </Menu>
                {/*<Button sx={{display:"block",color:"white", fontSize:"10px",mx:"auto", "&:hover":{textDecoration:"underline"}}} onClick={handleLogout}><ExitToApp style={{verticalAlign:"sub"}}/><span style={{verticalAlign:"super"}}>Déconnexion</span></Button>*/}
            </Box>
        </Drawer>
        <Container maxWidth="md" sx={{paddingLeft:{xs:"150px"}}}>
            <Routes>
                <Route path="" element={<Tickets/>}/>
                <Route path="ticket/:id" element={<Ticket/>}/>
                <Route path="creer" element={<Creer/>}/>
                <Route path="rapports" element={<RequireAdmin><Rapport/></RequireAdmin>}/>
                <Route path="recherche" element={<RequireAdmin><Recherche/></RequireAdmin>}/>
                <Route path="utilisateurs" element={<RequireAdmin><Utilisateurs/></RequireAdmin>}/>
                <Route path="utilisateur/:id" element={<RequireAdmin><Utilisateur/></RequireAdmin>}/>
            </Routes>
        </Container></>
    )
}

function CustomLink({ children, to, ...props }) {
    const theme = useTheme()
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
  
    return (
        <Link
          style={{ color: match ? "white" : theme.palette.gris.contour, textDecoration:"none", textAlign:"center", marginTop:20 }}
          to={to}
          {...props}
        >
          {children}
        </Link>
    );
  }


  function RequireAdmin({ children }) {
    const [userContext, setUserContext] = useContext(UserContext)
    let { id } = useParams()

    if(userContext.role==="admin" || (id!==undefined && id==userContext.id)){
        return children;
    }else{
        return <Navigate to="/admin" />;
    }
}