

export function Object_isEqual(a, b, keysToCompare=[]){
    if(!keysToCompare.length){
        keysToCompare = Object.keys({...a,...b})
    }
    Object.keys(a).forEach(k=>{
        if(!keysToCompare.includes(k)){
            delete a[k]
        }
    })
    Object.keys(b).forEach(k=>{
        if(!keysToCompare.includes(k)){
            delete b[k]
        }
    })

    if(Object.keys(a).length != Object.keys(b).length) return false

    for(let k in a){
        if(!b.hasOwnProperty(k) || a[k] != b[k])
            return false
    }
    return true
}

export function Object_whatIsDiff(a,b, keysToCompare=[]){
    if(!keysToCompare.length){
        keysToCompare = Object.keys({...a,...b})
    }
    Object.keys(a).forEach(k=>{
        if(!keysToCompare.includes(k)){
            delete a[k]
        }
    })
    Object.keys(b).forEach(k=>{
        if(!keysToCompare.includes(k)){
            delete b[k]
        }
    })

    let toReturn = []
    for(let k of keysToCompare){
        if(!a.hasOwnProperty(k) || !b.hasOwnProperty(k) || a[k] != b[k])
            toReturn.push(k)
    }
    return toReturn
}