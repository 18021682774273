import React, { useEffect, useState, useContext, createContext, useCallback } from 'react';
import {BrowserRouter as Router, Redirect, Route, Routes, useLocation, Navigate} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import Accueil from './scenes/Accueil';
import Admin from './scenes/Admin';
import {Box, CircularProgress } from "@mui/material"
import Login from './scenes/Login';
import { UserContext, UserProvider } from "./services/UserContext"
import API from './services/API';


const theme = createTheme({
    palette: {
        primary: {
            main: "#27264A"
        },
        secondary:{
            main: "#e10716"
        },
        disabled:{
            main: "#E9E9EC"
        },
        background: {
            main: "#F6F6F9",
        },
        gris:{
            contour:"#CCCCD1"
        }
    },
    typography: {
        fontFamily: "Poppins"
    }
});


export default function App({}){
    return (
        <ThemeProvider theme={theme}>
            <UserProvider>
                <Box sx={{bgcolor:"background.main", minHeight:"100vh", color:"primary.main"}}>
                    <Router>
                        <Routes>
                            {/*
                                    Taken from react-router docs:
                                    The Route component is perhaps the most important component in React Router to
                                    understand and learn to use well. Its most basic responsibility is to render some
                                    UI when a location matches the route’s path.
                                    https://reacttraining.com/react-router/web/api/Route
                                */}
                            <Route path="/" element={<Accueil/>}/>
                            <Route path="login" element={<Login/>}/>
                            <Route path="admin/*" element={<RequireAuth><Admin/></RequireAuth>}/>
                            {/*<Route path="/admin" component={()=><Admin loaded={loaded}/>}/>*/}

                            {/* 404 route, default if none of the above routes are satisfied */}
                            {/*<Route path="/404" component={NotFound}/>
                            <Redirect to="/404"/>*/}
                        </Routes>
                    </Router>
                </Box>
            </UserProvider>
        </ThemeProvider>
    );
}

function RequireAuth({ children }) {
    const [userContext, setUserContext] = useContext(UserContext)
    let location = useLocation();
    const isLocalhost = window.location.href.includes("localhost")

    const verifyUser = useCallback(() => {
        if(!isLocalhost){
            API("POST","utilisateur/refreshToken",{},{},"application/json",{credentials:"include"})
            .then(res=>{
                if (res.ok) {
                    setUserContext(currVal => ({ ...currVal, token: res.json.token }))
                } else {
                    setUserContext(currVal => ({ ...currVal, token: null }))
                }
                // call refreshToken every 5 minutes to renew the authentication token.
                setTimeout(verifyUser, 5 * 60 * 1000)
            })
        }
    }, [setUserContext])
    
    useEffect(() => {
        verifyUser()
    }, [verifyUser])


    /** Sync logout across tabs **/
    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
            // If using react-router-dom, you may call history.push("/")
            window.location.reload()
        }
    }, [])
    useEffect(() => {
        window.addEventListener("storage", syncLogout)
        return () => {
            window.removeEventListener("storage", syncLogout)
        }
    }, [syncLogout])




  
    if(userContext.token){
        return children;
    }else if (userContext.token===null || isLocalhost) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }else{
        return(
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{display:"block", mx:"auto", my:4}} />
            </Box>
        )
    }
}